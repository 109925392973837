






















/* tslint:disable:no-console */
import { Component, Vue } from "vue-property-decorator";

import NavBar from "@/components/Nav-bar.vue";
import BlockBox from "@/components/common/Block-box.vue";
import AppBlockNormal from "@/components/block/App-block-normal.vue";
import updateTdk from "@/utils/tdk";
import mixins from "@/mixin";
import routerMixin from "@/mixin/router-mixin.ts";

import { getHomePageData } from "@/api";
// @Component 修饰符注明了此类为一个 Vue 组件
@Component({
  name: "HotTagList",
  components: { NavBar, BlockBox, AppBlockNormal },
  mixins: [mixins, routerMixin]
})
export default class HotTagList extends Vue {
  // 初始数据可以直接声明为实例的属性
  private block: any = {};
  private navs: any[] = [];
  private pageMax: number = 20;
  private preNav: any[] = [];
  private tdk(self: any) {
    return {
      title: `-${self.$route.query.source_block_name}`
    };
  }
  private serverPrefetch(): any {
    updateTdk(this);
  }
  private mounted(): void {
    const self: any = this;
    this.preNav = [{ name: "搜索", link: "/searchDetail" }];
    self.navs.push({
      name: self.$route.query.source_block_name // self.utils.getUrlParam("source_block_name")
    });
    self.getData();
  }
  private getData(more?: string): any {
    const self: any = this;
    updateTdk(this);
    if (!more && !!self.block.data_source) {
      return Promise.resolve();
    }
    const rankId: number = Number(self.$route.query.source_rank_id) || 0;
    const blockType: string = self.$route.query.source_block_type || "";
    const blockId: number = Number(self.$route.query.source_block_id) || 0;
    // 只有客户端才会报弹窗错误
    if (!rankId && !self.isServer) {
      self.$toast("数据错误，请重试！");
      self.$router.push({
        name: "index"
      });
      return;
    }
    if (!more) {
      self.$doMotion(self, "LOADING_SWITCH", true);
    }
    return this.$store
      .dispatch("fetchAppList", {
        context: this,
        rankId,
        params: { blockType, blockId, start: self.currIndex, max: self.pageMax }
      })
      .then((result: any) => {
        const block = result.value;
        if (block) {
          block.data_source = "gameList";
          block.blockLink = "gameDetail";
          block.ctype = "game";
          if (self.block.data) {
            block.data = self.block.data.concat(block.data);
          }
          self.block = block;
          self.dataMore = block.more;
          if (!more) {
            self.loadStatus1 = "ready" + self.currIndex;
          } else {
            self.loadStatus1 = "loaded" + self.currIndex;
          }
        }
      })
      .catch(err => {
        self.$toast.show(err);
        self.loadStatus1 = "end" + self.currIndex;
      })
      .finally(() => {
        self.$doMotion(self, "LOADING_SWITCH", false);
      });
  }
}
